import React, {FC, useEffect, useState} from "react";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {useCreateQuestionnaireMutation} from "../questionnaireApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {buyerQuestions, sellerQuestions} from "../../../helpers/utils/Utils";

interface props {
    setActiveTab: any,
    skippedTabs: any,
    onComplete: any,
    partyId: string,
    setQuestionResponse: any,
    partyType: string
}

const QuestionnaireForm: FC<props> = ({
                                          setActiveTab,
                                          skippedTabs,
                                          onComplete,
                                          partyId,
                                          partyType,
                                          setQuestionResponse
                                      }) => {

    const [questionnaireApi, questionnaireApiResponse] = useCreateQuestionnaireMutation();
    const questions = partyType === "SELLER" ? sellerQuestions : buyerQuestions;

    const allQuestions = [...sellerQuestions, ...buyerQuestions];
    // State to track answers for each question
    const [answers, setAnswers] = useState<{ [key: number]: boolean | null }>(
        allQuestions.reduce((acc, question) => ({...acc, type: partyType, [question.name]: null}), {})
    );

    useEffect(() => {
        if (partyType) {
            setAnswers((prevAnswers) => ({
                ...prevAnswers,
                type: partyType,
            }));
        }
    }, [partyType]);

    const handleNavigation = async () => {
        await handleRTKQuery(
            async () => {
                return await questionnaireApi({requestBody: answers, partyId: partyId}).unwrap();
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setQuestionResponse(answers);
                    // @ts-ignore
                    const {type, ...filteredAnswers} = answers;
                    if (Object.values(filteredAnswers).every((answer) => (answer === false || answer == null))) {
                        setActiveTab("organization-detail");
                    } else {
                        skippedTabs.push("organization-detail");
                        skippedTabs.push("verify-contact");
                        setActiveTab("sign");
                    }
                    onComplete();
                }
            }
        );
    };

    const handleAnswerChange = (name: string, value: boolean) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [name]: value,
        }));
    };


    return (
        <>
            <div className="flex">
                <div className="w-full mt-5">
                    {/* Added content */}
                    <div className="text-text-1">
                        <h2 className="text-2xl font-semibold">{partyType === "SELLER" ? "Is this transaction any of the following?" : "Is the entity that you are affiliated with regarding this transaction any of the following?"}</h2>
                        <div className="mt-4">
                            <ul className="mt-3 space-y-2">
                                <li>Select all that apply:</li>
                                {questions.map(({text, name}) => (
                                    <li>
                                        <input type={"checkbox"}
                                               onClick={() => handleAnswerChange(name, true)}/>
                                        <span className="ml-2">{text.split("\n").map((line, index) => (
                                            <React.Fragment key={index}>{line}<br/></React.Fragment>))}</span>

                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                    </div>

                    <div className={"mb-2"}>
                        <ButtonTray
                            buttons={[
                                {
                                    buttonProps: {
                                        btnText: "Back",
                                        onClick: () => {
                                            setActiveTab("verify-code");
                                        },
                                        type: "close",
                                    },
                                    buttonType: "button"
                                },
                                {
                                    buttonProps: {
                                        btnText: "Save & Continue",
                                        type: "submit",
                                        isLoading: questionnaireApiResponse.isLoading,
                                        isVisible: true,
                                        onClick: handleNavigation
                                    },
                                    buttonType: "button",
                                }
                            ]}
                            align="end"
                            gap={2}
                            addTopLine={false}
                        />
                    </div>


                </div>
            </div>
        </>
    );
};

export default QuestionnaireForm;
