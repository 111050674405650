import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";

export const sellerApi = strApi.injectEndpoints({

    endpoints: (build) => ({

        createBuyer: build.mutation({
            query: ({requestBody}) => {
                return {
                    url: `/fincen-buyer`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Organization");
            },
        }),
    })
})

export const {
    useCreateBuyerMutation
} = sellerApi