import React, {ChangeEvent, FC, useEffect, useState} from "react";
import InputField from "../../../components/common/form/InputField";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import SendLinkModal from "../modal/SendLinkModal";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetContactByIdQuery, useUpdateContactMutation} from "../contactApi";
import {skipToken} from "@reduxjs/toolkit/query";

interface props {
    setActiveTab: any,
    formType: string,
    updatingContactId?: string,
    setContactReferenceId: any,
    partyId: string
}

const ContactForm: FC<props> = ({setActiveTab, formType, updatingContactId, setContactReferenceId, partyId}) => {

    const [contactData, setContactData] = useState<Record<string, any>>({
        type: "MEMBER",
        partyId: partyId,
        status: "NOT_INVITED"
    });
    const [showConfirmationModel, setShowConfirmationModel] = useState<boolean>();

    const [updateContactApi, updateContactApiResponse] = useUpdateContactMutation();
    const getContactByIdApi = useGetContactByIdQuery(updatingContactId ?? skipToken);

    useEffect(() => {
        let obj = getContactByIdApi?.data;
        if (obj) {
            setContactData(obj);
        }
    }, [getContactByIdApi?.data])

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (formType === "Add") {
            setShowConfirmationModel(true);
        } else {
            await handleRTKQuery(
                async () => {
                    return await updateContactApi({id: updatingContactId, requestBody: contactData}).unwrap();
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        setContactData({});
                        setActiveTab("verify-contact");
                    }
                }
            );
        }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setContactData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="w-full">
                <form onSubmit={handleSubmit} className="w-3/5 m-auto mt-4">
                    <div className="col-span-6 pb-4 mt-2">
                        <div className="col-span-12 p-4 relative rounded-xl">

                            <p className="text-2xl text-center text-text-1 mb-4">{formType} Contact Information</p>

                            <InputField label="Name" labelPosition="left" name={"name"} onChange={handleOnChange}
                                        value={contactData?.name || ""} required={true}/>
                            <InputField label="Email" labelPosition="left" name={"emailAddress"} type={"email"}
                                        onChange={handleOnChange}
                                        value={contactData?.emailAddress || ""} required={true}/>
                            <InputField label="Phone" labelPosition="left" onChange={handleOnChange} type={"number"}
                                        value={contactData?.phoneNumber || ""} name={"phoneNumber"} required={true}/>


                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Back",
                                            onClick: () => setActiveTab("verify-contact"),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: updateContactApiResponse.isLoading,
                                            isVisible: true,
                                        },
                                        buttonType: "button",
                                    }
                                ]}
                                align="end"
                                gap={2}
                                addTopLine={false}
                            />

                        </div>
                    </div>
                </form>
            </div>

            {showConfirmationModel &&
                <SendLinkModal
                    show={showConfirmationModel}
                    setShow={setShowConfirmationModel}
                    setActiveTab={setActiveTab}
                    contactData={contactData}
                    setContactReferenceId={setContactReferenceId}
                />
            }
        </>
    )
}
export default ContactForm;