import ModalButton from "../../components/common/form/ModalButton";
import {FaPlus, FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../components/layout/modal/ConfirmationModal";
import React, {FC, FormEvent, useState} from "react";
import {IoIosArrowDown} from "react-icons/io";
import IconButton from "../../components/common/form/IconButton";
import {FaFileSignature} from "react-icons/fa";
import {skipToken} from "@reduxjs/toolkit/query";
import {useDeleteContactByIdMutation, useGetAllContactByPartyIdQuery} from "./contactApi";
import SkeletonLoader from "../../components/common/SkeletonLoader";
import {handleRTKQuery} from "../../helpers/utils/RtkQueryUtils";
import {getEnumDisplayNameByConstant} from "../../helpers/utils/EnumUtils";
import {useGetEnumsQuery} from "../../app/globalApi";
import {getContactStatusStyle} from "../../helpers/utils/StyleUtils";
import {hasPermission} from "../../helpers/utils/AccessControlUtils";

interface Props {
    setActiveTab: (tab: string) => void;
    tabsWithUnsavedChanges: any,
    setTabsWithUnsavedChanges: any,
    activeTab: string,
    partyId: string,
    setUpdatingContactId: any
}

const ContactDetailPage: FC<Props> = ({
                                          setActiveTab,
                                          tabsWithUnsavedChanges,
                                          setTabsWithUnsavedChanges,
                                          activeTab,
                                          partyId,
                                          setUpdatingContactId
                                      }) => {

    const allContactsByPartyId = useGetAllContactByPartyIdQuery(partyId ?? skipToken);
    const [deleteContactApi, {isLoading: deleteContactApiResponse}] = useDeleteContactByIdMutation();

    const [selectedId, setSelectedId] = useState<number>();
    const [expandedContacts, setExpandedContacts] = useState<number[]>([]); // Store expanded contact IDs
    const {data: enums} = useGetEnumsQuery("");

    const handleDeleteSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedId) {
            await handleRTKQuery(
                async () => {
                    const apiResponse = await deleteContactApi(selectedId).unwrap();
                    setExpandedContacts([]);
                    return apiResponse;
                }
            );
        }
    };

    const toggleCollapse = (id: number) => {
        setExpandedContacts((prev) =>
            prev.includes(id) ? prev.filter((contactId) => contactId !== id) : [...prev, id]
        );
    };

    function getElement(label: string, value: string) {
        return <div className="col-span-12 grid grid-cols-12 mt-3.5">
            <div className="col-span-5">
                <div className="text-text-3 font-semibold">{label}</div>
            </div>
            <div className="col-span-7">
                <div className="text-text-1 " title="">{value}</div>
            </div>
        </div>
    }

    return (
        <>
            <div className="w-3/5">

                <div className="flex justify-center items-center w-full mb-4">
                    <span className="text-text-1 text-center text-2xl mb-3">Contact Details</span>

                    <div className="flex gap-2 absolute right-10">
                        {hasPermission(["FINCON-C"]) && <IconButton
                            icon={<FaPlus/>}
                            hasBackground={true}
                            onClick={() => {
                                setActiveTab("add-contact");
                            }}
                            title={"Add Contact"}
                            label={"Add Contact"}
                        />}
                        <IconButton
                            icon={<FaFileSignature/>}
                            hasBackground={true}
                            onClick={() => {
                                if (!tabsWithUnsavedChanges.includes('organization-detail')) {
                                    let list = tabsWithUnsavedChanges.filter((el: any) => el !== activeTab);
                                    setTabsWithUnsavedChanges(list);
                                    setActiveTab("sign");
                                }
                            }}
                            title={tabsWithUnsavedChanges.includes('organization-detail') ? "Unsaved Organization detail found" : "Sign & Complete"}
                            label={"Sign & Complete"}
                            isDisabled={tabsWithUnsavedChanges.includes('organization-detail')}
                        />
                    </div>
                </div>


                {allContactsByPartyId.isLoading && Array.from({length: 3}).map((_, index) => (
                    <div key={index} className="rounded-xl border border-surface-2 mb-4">
                        <div className="justify-between p-3 border-surface-2">
                            <SkeletonLoader count={1}/>
                        </div>
                    </div>
                ))}


                {allContactsByPartyId?.data?.length === 0 &&
                    <div className="rounded-xl border border-surface-2 mb-4">
                        <div className="justify-between p-3 border-surface-2">
                            <h3 className="text-text-2">No data found to display</h3>
                        </div>
                    </div>
                }

                {allContactsByPartyId?.data?.map((obj: any, index: number) => (
                    <div key={index} className="rounded-xl border border-surface-2 mb-4">
                        <div
                            className={`cursor-pointer flex justify-between p-3 border-surface-2 ${expandedContacts.includes(index) ? "border-b" : ""}`}
                            onClick={() => toggleCollapse(index)}>
                            <div className="flex gap-1 items-center">
                                <h3 className="text-text-2">{obj.name}</h3>
                            </div>
                            <div className="flex gap-3 items-center">
                                <span
                                    className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-text-1 ` + getContactStatusStyle(obj.status)}>{getEnumDisplayNameByConstant(enums?.["ContactStatus"], obj.status)}</span>
                                <IoIosArrowDown
                                    className={`text-text-1 text-lg cursor-pointer transition-transform duration-200 ${expandedContacts.includes(index) ? "rotate-180" : ""}`}
                                />
                            </div>
                        </div>

                        {expandedContacts.includes(index) && (
                            <div className="p-3 text-text-1">
                                {getElement("Name", obj.name)}
                                {getElement("Email", obj.emailAddress)}
                                {getElement("Phone", obj.phoneNumber)}
                            </div>
                        )}

                        {/* Buttons */}
                        {expandedContacts.includes(index) && (
                            <div className="flex justify-end space-x-4 mb-3 mr-3">

                                {hasPermission(["FINCON-D"]) && <ModalButton
                                    triggerButton={
                                        <button
                                            className="inline-flex px-3 py-1 items-center rounded bg-surface-4 text-text-1 hover:bg-highlight-7 transition duration-200">
                                            <FaTrash className="mr-2"/> Delete
                                        </button>
                                    }
                                    onOpen={() => setSelectedId(obj.id)}
                                    modal={
                                        <ConfirmationModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            title="Delete Contact"
                                            bodyMessage="Are you sure you want to delete this contact?"
                                            onSubmit={handleDeleteSubmit}
                                            isLoading={deleteContactApiResponse}
                                        />
                                    }
                                />}

                                {hasPermission(["FINCON-U"]) && <button
                                    className="inline-flex px-2 py-1 items-center rounded bg-highlight-3 text-text-1 hover:bg-highlight-7 transition duration-200"
                                    onClick={() => {
                                        setUpdatingContactId(obj.id)
                                        setActiveTab("update-contact")
                                    }}
                                >
                                    Update
                                </button>}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default ContactDetailPage;
