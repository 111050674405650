import React, {FC, useEffect, useState} from "react";
import InputField from "../../../components/common/form/InputField";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {useCreateSellerMutation} from "../sellerApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetAllStatesByCountryIdQuery} from "../../data/states/statesApi";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useGetAllCountriesQuery} from "../../data/countries/countriesApi";

interface Props {
    activeTab: string
    setActiveTab: any;
    tabsWithUnsavedChanges: any;
    setTabsWithUnsavedChanges: any;
    partyId: string,
    setOrgDetailData: any
}

const SellerForm: FC<Props> = ({
                                   activeTab,
                                   setActiveTab,
                                   tabsWithUnsavedChanges,
                                   setTabsWithUnsavedChanges,
                                   partyId,
                                   setOrgDetailData
                               }) => {

    const [currentTab, setCurrentTab] = useState("1");
    const [stateId, setStateId] = useState<any>(null);
    const [countryId, setCountryId] = useState<any>(null);
    const [createSellerApi, createSellerApiResponse] = useCreateSellerMutation();
    const allStatesApiResult = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");
    const [soleProprietorship, setSoleProprietorship] = useState("");
    const allCountriesApiResult = useGetAllCountriesQuery("");

    const [organizationInfo, setOrganizationInfo] = useState({
        organizationName: "",
        natureOfBusiness: "",
        employerId: "",
    });


    // Update taxId only when entityType or soleProprietorship changes
    useEffect(() => {
        if (soleProprietorship === "true") {
            setOrganizationInfo(prevState => ({
                ...prevState,
                taxId: ""
            }));
        } else {
            setOrganizationInfo({
                organizationName: "",
                natureOfBusiness: "",
                employerId: "",
            });
        }
    }, [soleProprietorship]);

    const [contactInfo, setContactInfo] = useState({
        contactName: "",
        contactTitle: "",
        contactPhoneNumber: "",
    });

    const [addressInfo, setAddressInfo] = useState({
        streetAddress: "",
        city: "",
        zip: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, section: string | {
        name: string;
        value: any
    }) => {
        const {name, value} = e.target;
        if (section === "organization") {
            setOrganizationInfo({...organizationInfo, [name]: value});
        } else if (section === "contact") {
            setContactInfo({...contactInfo, [name]: value});
        } else if (section === "address") {
            setAddressInfo({...addressInfo, [name]: value});
        }
    };

    const isNextButtonEnabled = (obj: {}) => {
        let list = Object.keys(obj) as Array<keyof typeof obj>;
        // @ts-ignore
        return list?.every(cur => !isNullOrEmpty(obj?.[cur]))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        let list = tabsWithUnsavedChanges.filter((el: any) => el !== activeTab);
        setTabsWithUnsavedChanges(list);

        let obj = {
            requestBody: {
                partyId: partyId,
                stateId: stateId?.value,
                soleProprietorship: soleProprietorship,
                ...organizationInfo,
                ...contactInfo,
                ...addressInfo
            }
        }

        await handleRTKQuery(
            async () => {
                return await createSellerApi(obj).unwrap();
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setOrgDetailData(obj?.requestBody);
                    setActiveTab(`verify-contact`);
                }
            }
        );

    };

    const handleButton = (backAction: any, continueAction: any, buttonLabel: string, obj: {}) => {
        return (
            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Back",
                            onClick: backAction,
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: buttonLabel,
                            type: "submit",
                            isLoading: createSellerApiResponse.isLoading,
                            isVisible: true,
                            onClick: continueAction,
                            isDisabled: !isNextButtonEnabled(obj)
                        },
                        buttonType: "button",
                    }
                ]}
                align="end"
                gap={2}
                addTopLine={false}
            />
        );
    };

    const organizationFieldLabels = {
        organizationName: "Organization’s Name",
        natureOfBusiness: "Nature Of Business",
        taxId: "Tax ID",
        employerId: "Employer Id",
    };

    const contactFieldLabels = {
        contactName: "Contact Name",
        contactTitle: "Contact Title",
        contactPhoneNumber: "Phone Number",
    };

    const addressFieldLabels = {
        streetAddress: "Street Address",
        city: "City",
        zip: "ZIP",
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <div className="m-auto mt-4">

                {currentTab === "1" &&
                    <div className="text-text-1">
                        <h2 className="text-2xl font-semibold">Is this entity a sole proprietorship?</h2>

                        <div className={"mt-3 text-md"}>
                            <label>
                                <input
                                    type="radio"
                                    name="soleProprietorship"
                                    value="true"
                                    className="mr-2"
                                    checked={soleProprietorship === "true"}
                                    onChange={(e: any) => {
                                        setSoleProprietorship(e.target.value);
                                    }}
                                />
                                Yes
                            </label>
                        </div>
                        <div className={"mt-3 text-md"}>
                            <label>
                                <input
                                    type="radio"
                                    name="soleProprietorship"
                                    value="false"
                                    className="mr-2"
                                    checked={soleProprietorship === "false"}
                                    onChange={(e: any) => {
                                        setSoleProprietorship(e.target.value);
                                    }}
                                />No
                            </label>
                        </div>

                        {handleButton(() => setActiveTab("questions"), () => setCurrentTab("2"), "Next", soleProprietorship === "")}

                    </div>
                }

                {/* Organization Info Section */}
                {currentTab === "2" && <>
                    <p className="text-2xl text-center text-text-1 mb-4">Organization Information</p>
                    {(Object.keys(organizationInfo) as Array<keyof typeof organizationInfo>).map((key, index) => (
                        <InputField
                            key={index}
                            label={organizationFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                            value={organizationInfo[key]}
                            type="text"
                            onChange={(e) => handleInputChange(e, "organization")}
                            name={key}
                            labelPosition="left"
                            required={true}
                        />
                    ))}
                    {handleButton(() => setCurrentTab("1"), () => setCurrentTab("3"), "Continue", organizationInfo)}
                </>}

                {/* Contact Info Section */}
                {currentTab === "3" &&
                    <>
                        <p className="text-2xl text-center text-text-1 mb-4">Contact Information</p>
                        {(Object.keys(contactInfo) as Array<keyof typeof contactInfo>).map((key, index) => (
                            <InputField
                                key={index}
                                label={contactFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                value={contactInfo[key]}
                                type={key !== "contactPhoneNumber" ? "text" : "number"}
                                onChange={(e) => handleInputChange(e, "contact")}
                                name={key}
                                labelPosition="left"
                                required={true}
                            />
                        ))}
                        {handleButton(() => setCurrentTab("2"), () => setCurrentTab("4"), "Continue", contactInfo)}
                    </>
                }

                {/* Address Info Section */}
                {currentTab === "4" && <>
                    <p className="text-2xl text-center text-text-1 mb-4">Address Information</p>

                    <SelectField
                        label={"Country"}
                        value={countryId}
                        placeholder="Select Country..."
                        options={createOptionListForSelectTag(allCountriesApiResult?.data, "name", "id")}
                        onChange={(selectedOption) => {
                            setCountryId(selectedOption)
                        }}
                        required={true}
                    />

                    <SelectField
                        label={"State"}
                        value={stateId}
                        placeholder="Select State..."
                        options={createOptionListForSelectTag(allStatesApiResult?.data, "name", "id")}
                        onChange={(selectedOption) => {
                            setStateId(selectedOption)
                        }}
                        required={true}
                    />

                    {(Object.keys(addressInfo) as Array<keyof typeof addressInfo>).map((key, index) => (
                        <InputField
                            key={index}
                            label={addressFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                            value={addressInfo[key]}
                            type="text"
                            onChange={(e) => handleInputChange(e, "address")}
                            name={key}
                            labelPosition="left"
                            required={true}
                        />
                    ))}
                    {handleButton(() => setCurrentTab("3"), handleSubmit, "Submit", {
                        ...addressInfo,
                        stateId: stateId?.value || null
                    })}
                </>
                }
            </div>
        </form>
    );
};

export default SellerForm;
