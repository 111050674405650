import React from "react";

const SuccessPage = () => {

    return (
        <>

            <div className="text-text-1 mb-4 mt-8">
                <h4 className="text-2xl font-semibold">Thank you for attesting to the accuracy of your
                    statement.</h4>
                <h4 className={"mt-2"}> Your transaction has been successfully processed and no further
                    action is needed.</h4>
            </div>
        </>
    );
}

export default SuccessPage;
