// @ts-ignore
import logo from "../../images/logo.svg";
import React from "react";

const Header = () => {

    return (
        <header
            className="top-0 sm:block py-1 lg:flex md:block z-50 items-center fixed w-full header bg-surface-1 px-2 border-b-2 border-highlight-2">
            <div className="sm:flex justify-between lg:block md:flex flex items-center"><a
                className="flex items-center space-x-2" href="/">
                <img src={logo} alt="Company Logo" className="h-10"/></a>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
                     className="text-text-1 lg:hidden sm:block md:block" height="1em" width="1em"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
                </svg>
            </div>
        </header>
    )
}

export default Header;