import {FC, FormEvent, useState} from "react";
// @ts-ignore
import logo from "../../images/logo.svg";
import Form from "../../components/common/form/Form"
import Button from "../../components/common/form/Button";
import {useNavigate} from "react-router-dom";
import {useForgotPasswordMutation} from "../user/users/usersApi";
import {handleRTKQuery} from "../../helpers/utils/RtkQueryUtils";
import {showToast} from "../../helpers/utils/Utils";
import {Routes as AppRoutes} from "../../routes";
import InputField from "../../components/common/form/InputField";
import Footer from "../../components/layout/footer/Footer";

const ForgotPassword: FC = () => {

    const navigate = useNavigate();
    const [forgotPasswordApi, forgotPasswordApiResponse] = useForgotPasswordMutation()
    const [emailId, setEmailId] = useState<String>("");

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                return await forgotPasswordApi(emailId).unwrap();
            },
            () => {
                setEmailId("");
                showToast("A password reset email has been sent to the specified email address, if there is a user associated with it!")
                navigate(AppRoutes.Login.path);
            }
        )
    };

    return (
        <>
            <section className="login_form flex items-center justify-center ">
                <div className="container mx-auto px-4">
                    <div className="flex justify-center">
                        <div className="w-full max-w-xl lg:max-w-lg md:max-w-md">
                            <div className="log_in relative z-10">
                                <Form onSubmit={handleSubmit} fields={
                                    <>
                                        <div className="text-center mb-4">
                                            <img loading="lazy" src={logo} alt=""/>
                                        </div>

                                        <div className="form-group mb-4">

                                            <InputField
                                                   name="email"
                                                   type="email"
                                                   onChange={(e: any) => {
                                                       setEmailId(e.target.value);
                                                   }}
                                                   required={true}
                                                   className="form-control"
                                                   placeholder="Email"
                                                   hideLabel={true}
                                            />
                                        </div>

                                        <div className="form-group mb-4">
                                            <Button btnText={"Send Reset Password"}
                                                    isLoading={forgotPasswordApiResponse.isLoading}
                                                    type={"submit"}></Button>
                                        </div>

                                        <div className="forgot-password-txt mb-3">
                                            <button className=" hover:text-blue-700 cursor-pointer"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate("/login")
                                                    }}>
                                                Remembered Password?
                                            </button>
                                        </div>

                                    </>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default ForgotPassword;