import Modal from "../../../components/common/form/Modal";
import React, {FC, useState} from "react";

import ButtonTray from "../../../components/common/form/ButtonTray";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useCreateContactMutation} from "../contactApi";

interface Props {
    setActiveTab: any,
    setShow: any,
    show: any,
    contactData: any,
    setContactReferenceId: any
}

const SendLinkModal: FC<Props> = ({show, setShow, setActiveTab, contactData, setContactReferenceId}) => {

    const [createContactApi, createContactApiResponse] = useCreateContactMutation();
    const [buttonClicked, setButtonClicked] = useState<String>("");

    const handleContactSubmit = async (e: any, isRedirectToMember = false) => {
        e.preventDefault();
        setButtonClicked(isRedirectToMember ? "enter information" : "send link");
        await handleRTKQuery(
            async () => {
                const apiResponse = await createContactApi({
                    requestBody: contactData,
                    isSendLink: !isRedirectToMember
                }).unwrap();
                setContactReferenceId(apiResponse.id);
                return apiResponse;
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setShow(false);
                    setActiveTab(isRedirectToMember ? "member-detail" : "verify-contact");
                }
            }
        );
    };


    return (
        <Modal title={"Invite Confirmation"} show={show} setShow={setShow} className="mx-4"
               fields={

                   <>
                       <h3 className="mb-5 text-base font-normal text-text-1">Do you want to send an invite, or enter
                           the information yourself?</h3>

                       <ButtonTray
                           buttons={[
                               {
                                   buttonProps: {
                                       btnText: "Send Invite",
                                       onClick: (e) => {
                                           handleContactSubmit(e).then(r => {
                                           })
                                       },
                                       type: "submit",
                                       isLoading: buttonClicked === "send link" && createContactApiResponse.isLoading
                                   },
                                   buttonType: "button"
                               },
                               {
                                   buttonProps: {
                                       btnText: "Enter Information",
                                       type: "submit",
                                       onClick: (e) => {
                                           handleContactSubmit(e, true).then(r => {
                                           })
                                       },
                                       isLoading: buttonClicked === "enter information" && createContactApiResponse.isLoading
                                   },
                                   buttonType: "button",
                               }
                           ]}
                           align="end"
                           gap={2}
                       />
                   </>
               }
        />
    );
};

export default SendLinkModal;