import React, {FC, useRef, useState} from "react";
// @ts-ignore
import SignatureCanvas from "react-signature-canvas";
import ButtonTray from "../../../components/common/form/ButtonTray";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {FaTrash, FaUpload} from "react-icons/fa6";
import PdfDocument from "../PdfDocument";
import {showToast} from "../../../helpers/utils/Utils";
import {pdf, PDFViewer} from "@react-pdf/renderer";
import {Provider} from "react-redux";
import store from "../../../app/store";
import {useGetAllContactByPartyIdQuery} from "../contactApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useUploadAttestationMutation} from "../attestationApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useParams} from "react-router-dom";

interface props {
    partyType: string,
    setSkippedTabs: any,
    setActiveTab: any,
    skippedTabs: [],
    partyId: string,
    orgDetailData: any,
    questionResponse: [],
    productId: string
}

const SignatureForm: FC<props> = ({
                                      partyType,
                                      setSkippedTabs,
                                      setActiveTab,
                                      skippedTabs,
                                      partyId,
                                      orgDetailData,
                                      questionResponse,
                                      productId
                                  }) => {

    const {contactId} = useParams();
    const allContactsByPartyId = useGetAllContactByPartyIdQuery(partyId ?? skipToken);
    const [uploadAttestationApi, uploadAttestationApiResponse] = useUploadAttestationMutation();

    const [signature, setSignature] = useState<string | null>(null);
    const sigCanvas = useRef<SignatureCanvas | null>(null);
    const [showModal, setShowModal] = useState(false);

    // Function to save the signature as an image
    const saveSignature = () => {
        if (sigCanvas.current.isEmpty()) {
            showToast(`Please provide a signature before saving.`, "warning");
            return;
        }
        if (sigCanvas.current) {
            setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const blob = await pdf(
            <PdfDocument
                partyType={partyType}
                orgDetailData={orgDetailData}
                questionResponse={questionResponse}
                signature={signature}
                skippedTabs={skippedTabs}
                allContactsByPartyId={allContactsByPartyId}
            />
        ).toBlob();

        console.log("PDF generated successfully:", blob);
        console.log("Blob Size:", blob.size);  // Log size

        // Convert Blob to File for Multipart FormData
        const file = new File([blob], 'generated-document.pdf', {type: 'application/pdf'});

        // Create FormData
        const formData = new FormData();
        formData.append('file', file);

        await handleRTKQuery(
            async () => {
                return await uploadAttestationApi({
                    contactId: contactId,
                    formData: formData,
                    productId: productId
                }).unwrap(); // Ensure `unwrap()` is used to handle errors properly
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setShowModal(false);
                    setActiveTab("success");
                }
            }
        );
    }

    return (
        <>
            <div className="flex flex-col items-center">
                <p className="text-lg text-center text-text-1">
                    Based on your responses, no FinCEN reporting is required at this time.<br/>
                    To complete this process, please review the statement below and provide your attestation.
                </p>

                <div className="mt-7 border shadow-lg rounded-lg overflow-hidden w-full max-w-4xl pdf-viewer">

                    <PDFViewer style={{width: "100%", height: "600px"}}>
                        <PdfDocument partyType={partyType}
                                     orgDetailData={orgDetailData} questionResponse={questionResponse}
                                     signature={signature} skippedTabs={skippedTabs}
                                     allContactsByPartyId={allContactsByPartyId}/>
                    </PDFViewer>
                </div>

                <div>
                    <p className={"mt-8 text-text-1"}>Pen your signature here</p>

                    {/*TODO Make pen white but keep it black on PDF - white looks better on dark background*/}
                    <div className="relative border rounded-lg border-text-2 mt-2">
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor="black"
                            canvasProps={{
                                width: 850,
                                height: 150,
                                className: "w-full h-full",
                            }}
                        />

                        {/*// TODO: Use button tray and change to icon buttons*/}
                        {/* Buttons Inside Canvas */}
                        <div className="absolute bottom-2 right-2 flex gap-2">
                            {/* Clear Button */}
                            <button
                                onClick={() => {
                                    sigCanvas.current?.clear()
                                    setSignature(null);
                                }}
                                className="bg-red-500 text-white p-2 rounded-full shadow hover:bg-red-700"
                                title={"Clear"}
                            ><FaTrash size={18} className={"text-text-1"}/></button>

                            {/* Upload Button */}
                            <button
                                onClick={() => {
                                    saveSignature();
                                }} className="bg-red-500 text-white p-2 rounded-full shadow hover:bg-red-700"
                                title={"Apply signature to PDF"}
                            ><FaUpload size={18} className={"text-text-1"}/></button>
                        </div>
                    </div>

                    <div className={"mb-3"}>

                        <ButtonTray
                            buttons={[
                                {
                                    buttonProps: {
                                        btnText: "Back",
                                        onClick: () => {
                                            setSkippedTabs([]);
                                            setActiveTab(skippedTabs.length > 0 ? "questions" : "verify-contact");
                                        },
                                        type: "close",
                                    },
                                    buttonType: "button"
                                },
                                {
                                    buttonProps: {
                                        btnText: "Submit",
                                        type: "submit",
                                        isLoading: false,
                                        isVisible: true,
                                        isDisabled: !signature,
                                        onClick: () => {
                                            setShowModal(true);
                                        }
                                    },
                                    buttonType: "button",

                                }
                            ]}
                            align="end"
                            gap={2}
                            addTopLine={false}
                        />
                    </div>

                </div>

            </div>

            {showModal && <ConfirmationModal
                show={true}
                setShow={setShowModal}
                title={"Submit Your Signature"}
                bodyMessage={`Are you sure you want to submit this form?`}
                onSubmit={(e: any) => {
                    handleSubmit(e).then(r => {
                    });
                }}
                isLoading={uploadAttestationApiResponse.isLoading}
                waitForResponse={true}
            />}
        </>
    )

};

export default SignatureForm;
