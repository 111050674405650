import Modal from "../../../components/common/form/Modal";
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import Button from "../../../components/common/form/Button";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import InputField from "../../../components/common/form/InputField";
import {
    useCreateContactMutation,
    useGetContactByIdQuery,
    useUpdateContactMutation
} from "../../fincenReporting/contactApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import Form from "../../../components/common/form/Form";
import {skipToken} from "@reduxjs/toolkit/query";


interface props {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    contactId?: string,
    partyId?: string,
}

const AddEditContactForm: FC<props> = ({show, setShow, contactId, partyId}) => {

    const [contactData, setContactData] = useState<any>({});

    const [updateContactApi, updateContactApiResponse] = useUpdateContactMutation();
    const [createContactApi, createContactApiResponse] = useCreateContactMutation();
    const getContactByIdApi = useGetContactByIdQuery(contactId ?? skipToken);

    useEffect(() => {
        let obj = getContactByIdApi?.data;
        if (obj) {
            setContactData(obj);
        }
    }, [getContactByIdApi?.data]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setContactData({
            ...contactData,
            [name]: value
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!contactId) {
            await handleRTKQuery(
                async () => {
                    return await createContactApi({
                        requestBody: {
                            ...contactData,
                            primaryContact: false,
                            type: "MEMBER",
                            partyId: partyId,
                            status: "NOT_INVITED"
                        },
                        isSendLink: false
                    }).unwrap();
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        setContactData({});
                        setShow(false);
                    }
                }
            );

        } else {
            await handleRTKQuery(
                async () => {
                    return await updateContactApi({id: contactId, requestBody: contactData}).unwrap();
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        setContactData({});
                        setShow(false);
                    }
                }
            );
        }
    }

    return (
        <>
            <Modal
                title={contactId ? "Update Contact" : "Add Contact"}
                show={show}
                setShow={setShow}
                className="w-3/5 mx-4"
                fields={
                    <Form onSubmit={handleSubmit} fields={
                        <>

                            <InputField
                                label="Name"
                                name={"name"}
                                value={contactData?.name || ""}
                                type="text"
                                onChange={handleOnChange}
                            />

                            <InputField
                                label="Email"
                                name={"emailAddress"}
                                value={contactData?.emailAddress || ""}
                                type="text"
                                onChange={handleOnChange}
                            />

                            <InputField
                                label="Phone No"
                                name={"phoneNumber"}
                                value={contactData?.phoneNumber || ""}
                                type="text"
                                onChange={handleOnChange}
                            />

                            <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                                <Button btnText="Close" isLoading={false} type="close" onClick={() => setShow(false)}/>
                                {
                                    (contactId ? hasPermission(["FINCON-U"]) : hasPermission(["FINCON-C"])) &&
                                    <Button btnText={contactId ? "Update" : "Submit"}
                                            isLoading={contactId ? updateContactApiResponse.isLoading : createContactApiResponse.isLoading}
                                            type="submit"
                                            isDisabled={false}/>
                                }
                            </div>
                        </>
                    }/>
                }
            />
        </>
    )
}
export default AddEditContactForm;