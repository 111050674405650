import React, {FC, useEffect, useState} from "react";
import Breadcrumb from "../../components/common/Breadcrumbs";
import {hasPermission, jwtDecode} from "../../helpers/utils/AccessControlUtils";
import {
    useFindUserSettingsByGroupNameQuery
} from "../../components/common/userSetting/userSettingsApi";
import {
    createClickableLink,
    SearchSelectWithIndeterminateBoolean
} from "../../components/common/datatable/DataTableUtils";
import {formatValueAsDate} from "../../helpers/utils/DateUtils";
import {useLazyExportProductDataQuery, useSearchProductsQuery} from "./productsApi";
import {DataTable} from "../../components/common/datatable/DataTable";
import {SearchInputWrapper} from "../../components/common/datatable/filter/SearchInputWrapper";
import {SearchSelectWithEnum, SearchSelectWithList} from "../../components/common/datatable/filter/SearchSelectWrapper";
import {useGetEnumsQuery} from "../../app/globalApi";
import {useNavigate} from "react-router-dom";
import {SearchDatePickerWrapper} from "../../components/common/datatable/filter/SearchDatePickerWrapper";
import {useLazySearchCustomersQuery} from "../user/customer/customerApi";
import {FaPlus} from "react-icons/fa6";
import IconButton from "../../components/common/form/IconButton";
import {getEnumDisplayNameByConstant} from "../../helpers/utils/EnumUtils";
import {FaTag} from "react-icons/fa";
import FollowUpTypeModal from "./modal/FollowUpTypeModal";
import ModalButton from "../../components/common/form/ModalButton";
import {useSearchFollowUpTypesQuery} from "../tenantAdmin/followUpTypes/followUpTypesApi";
import ColorTag from "./ColorTag";
import {DataTableColumn} from "../../components/common/datatable/DataTableTypes";
import { useLazyGetAllTenantsQuery} from "../user/customer/tenantApi";

const Orders: FC = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Orders", url: ""},
    ])
    const [searchCustomerApi, customerData] = useLazySearchCustomersQuery();

    const followUpTypes = useSearchFollowUpTypesQuery("");
    const [customFollowUpList, setCustomFollowUpList] = useState<any>([]);

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Order");
    const {data: enums} = useGetEnumsQuery("");
    const [triggerExportProductDataQuery] = useLazyExportProductDataQuery();
    const [tenantApi , tenantList] = useLazyGetAllTenantsQuery();

    useEffect(() => {
        document.title = "Orders";
    });

    useEffect(() => {
        if (jwtDecode()?.customerLevel === "TENANT"){
            searchCustomerApi({
                pageSize: 1000,
                tenantId: jwtDecode()?.tenantId,
            });
        }
    }, [searchCustomerApi]);


    useEffect(() => {
        if (jwtDecode()?.customerLevel === "SYS"){
            tenantApi("");
        }
    }, [tenantApi]);


    useEffect(() => {
        if (followUpTypes?.data) {
            // Add "No Follow-up" option and merge with the original list
            const updatedFollowUpList = [
                {id: 'no-follow-up', name: 'No Follow-up', hexColor: '#FFFFFF'}, // Custom option
                {id: 'any', name: 'Any', hexColor: '#808080'}, // Custom option
                ...followUpTypes?.data.data
            ];
            setCustomFollowUpList(updatedFollowUpList);
        }
    }, [followUpTypes?.data]);

    const columns: DataTableColumn[] = [
        {
            label: "Order No.",
            accessor: "orderNumber",
            overrideSortColumn: "order.orderNumber",
            cell: (props: any) => {
                let path = `/orders/${props.row.original.orderId}#${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["ORDR-R", "ORDR-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'orderNumber'}
                    placeholder={'Enter Order Number...'}
                    name={'orderNumber'}/>
            }]
        },
        {
            label: "Status",
            accessor: "status",
            cell: (props: any) => {
                let followObj = props.row.original.followUp;
                let followUpTypeDTO = props.row.original.followUpTypeDTO;
                return (
                    <>
                        <div className={`flex gap-1 ${followObj ? '-ml-[18px]' : ''}`}>

                            {hasPermission(["PROFOLLUP-V"]) && followObj != null &&
                                <ModalButton
                                    triggerButton={
                                        <IconButton icon={
                                            <FaTag className={"text-sm"}
                                                   style={{color: followObj && followUpTypeDTO?.hexColor}}/>}
                                                    title={followUpTypeDTO?.name + " : " + formatValueAsDate(followObj?.followUpAdded) + ' ' + followObj?.followUpMessage}
                                                    onClick={() => ''}/>
                                    }
                                    modal={
                                        hasPermission(["PROFOLLUP-R"]) &&
                                        <FollowUpTypeModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            productId={props.row.original.id}
                                        />
                                    }
                                />
                            }
                            <span>{getEnumDisplayNameByConstant(enums?.["ProductStatus"], props.value)}</span>
                        </div>
                    </>
                )
            },
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["ProductStatus"], "Select Product Status(es)...", true, "statuses", null),
                overrideFilterAccessor: "statuses",
            }]
        },
        {
            label: "Type",
            accessor: "type",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["ProductType"], props.value),
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["ProductType"], "Select Product Type(es)...", true, "types", null),
                overrideFilterAccessor: "types",
            }]
        },
        {
            label: "Order Date", accessor: "creationDate", cell: (props: any) => {
                return formatValueAsDate(props.row.original.creationDate);
            }, filterOptions: [{
                filterComponent: <SearchDatePickerWrapper accessor={"createdDateFrom"}/>,
                overrideFilterAccessor: "createdDateFrom",
                overrideFilterLabel: "Created Date - From",
            }, {
                filterComponent: <SearchDatePickerWrapper accessor={"createdDateTo"}/>,
                overrideFilterAccessor: "createdDateTo",
                overrideFilterLabel: "Created Date - To",
            }]
        },

        {
            label: "Delivered Date", accessor: "lastDeliveredDate",
            visibility: {
                canColumnShow: false,
                showByDefault: false,
                hasShowSetting: false,
            },
            filterOptions: [{
                filterComponent: <SearchDatePickerWrapper accessor={"deliveredDateFrom"}/>,
                overrideFilterAccessor: "deliveredDateFrom",
                overrideFilterLabel: "Delivered Date - From",
            }, {
                filterComponent: <SearchDatePickerWrapper accessor={"deliveredDateTo"}/>,
                overrideFilterAccessor: "deliveredDateTo",
                overrideFilterLabel: "Delivered Date - To",
            }]
        },
        {
            label: "Tenant", accessor: "tenantName",
            visibility: {disallowedCustomerLevels: ["CUSTOMER", "TENANT"]},
            canSort: false,
            filterOptions: [{
                dependentColumns: ["Customer"],
                filterComponent: tenantList?.data ? SearchSelectWithList(tenantList?.data, (tenant: any) => `${tenant.name} `, "id", "Select Tenant...", false, "tenantId") : [],
                overrideFilterAccessor: "tenantId"
            }],
        },
        {
            label: "Customer",
            accessor: "customerName",
            overrideSortColumn: "customerId",
            visibility: {disallowedCustomerLevels: ["CUSTOMER"]},
            filterOptions: [{
                filterComponent: SearchSelectWithList(customerData?.data?.data, (customer: any) => `${customer.customerName} (${customer.customerNumber})`, "id", "Select Customer...", false, "customerId"),
                overrideFilterAccessor: "customerId",
                onDependencyUpdated: (value: string) => {
                    searchCustomerApi({
                        pageSize: 1000,
                        tenantId: value,
                    });
                }
            }],
            canSort: false,
        },
        {
            label: "Branch",
            accessor: "branchName",
            canSort: false
        },

        {
            label: "Follow Up Type",
            accessor: "followUpTypes",
            visibility: {
                canColumnShow: false,
                showByDefault: false,
                hasShowSetting: false,
            },
            filterOptions: [{
                filterComponent: SearchSelectWithList(customFollowUpList, (followUp: any) => <ColorTag
                    hexCode={followUp.hexColor}
                    followUpTypeName={followUp.name}/>, "id", "Select Follow Up Types...", true, "followUpTypes"),
            }],
        },
        {
            label: "Rush",
            accessor: "rush",
            visibility: {
                canColumnShow: false,
                showByDefault: false,
                hasShowSetting: false,
            },
            filterOptions: [{
                filterComponent: SearchSelectWithIndeterminateBoolean('Select Rush...', 'rush', 'Rushes only', 'No rushes', null, false)
            }],
        },
    ]

    return (
        <div className="px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
                <div>
                </div>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchProductsQuery,
                    exportAction: triggerExportProductDataQuery
                }}
                defaultFilter={{
                    sort: "creationDate",
                    sortOrder: "DESCENDING",
                    statuses: ['PREPARING', 'IN_PROGRESS', 'READY_TO_RECORD', 'COMPLETED', 'FOLLOW_UP', 'REJECTED']
                }}
                userSettingGroup='Order'
                buttonOptions={{
                    addButton: {
                        button: <IconButton
                            icon={<FaPlus/>}
                            hasBackground={true}
                            onClick={() => {
                                navigate("/orders/create")
                            }}
                            title={"Create Order"}
                        />,
                        permissions: ["ORDR-C"]
                    }
                }}
                defaultSortOptions={{
                    accessor: 'creationDate',
                    direction: 'desc'
                }}
                basePermission={"ORDR"}
            />
        </div>

    )
}
export default Orders