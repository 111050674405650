import InputField from "../../../components/common/form/InputField";
import React, {FC, useState} from "react";
import {useGetEnumsQuery} from "../../../app/globalApi";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../helpers/utils/Utils";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {useParams} from "react-router-dom";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../data/states/statesApi";
import {useCreateMemberMutation} from "../memberApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetAllCountriesQuery} from "../../data/countries/countriesApi";
import {skipToken} from "@reduxjs/toolkit/query";

interface props {
    setActiveTab: any,
    isPrimaryContact: boolean,
    partyId: string,
    contactReferenceId: string
}

const MemberForm: FC<props> = ({setActiveTab, isPrimaryContact, partyId, contactReferenceId}) => {

    const {contactId} = useParams();
    const [stateId, setStateId] = useState<any>(null);
    const [country, setCountry] = useState<any>(null);
    const [idIssuer, setIdIssuer] = useState<any>(null);

    const allStatesApiResult = useGetAllStatesByCountryIdQuery(country?.value ?? skipToken);
    const allCountriesApiResult = useGetAllCountriesQuery("");
    const {data: enums} = useGetEnumsQuery("");
    const [idType, setIdType] = useState<any>();
    const [currentTab, setCurrentTab] = useState("1");
    const [createMemberApi, createMemberApiResponse] = useCreateMemberMutation();

    const [personalInfoData, setPersonalInfoFormData] = useState({
        legalFirstName: "",
        legalMiddleInitial: "",
        legalLastName: "",
        dateOfBirth: "",
        occupation: "",
        taxId: ""
    });

    const [identificationInfoData, setIdentificationInfoData] = useState({
        idNumber: ""
    });

    const [addressInfoData, setAddressInfoData] = useState({
        streetAddress: "",
        city: "",
        zip: "",
    });

    const isNextButtonEnabled = (obj: {}) => {
        let list = Object.keys(obj) as Array<keyof typeof obj>;
        // @ts-ignore
        return list?.every(cur => !isNullOrEmpty(obj?.[cur]))
    }

    function handleButton(backAction: any, continueAction: any, buttonLabel: string, obj: {}) {
        return <>
            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Back",
                            onClick: backAction,
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: buttonLabel,
                            type: "submit",
                            isLoading: createMemberApiResponse.isLoading,
                            isVisible: true,
                            onClick: continueAction,
                            isDisabled: !isNextButtonEnabled(obj)
                        },
                        buttonType: "button",
                    }
                ]}
                align="end"
                gap={2}
                addTopLine={false}
            />

        </>
    }

    const handlePersonalInfoChange = (e: any) => {
        const { name, value } = e.target;
        let updatedValue = value;

        if (name === "legalMiddleInitial") {
            // Allow only a single alphabet (A-Z or a-z)
            updatedValue = value.slice(0, 1).replace(/[^A-Za-z]/g, "");
        }
        setPersonalInfoFormData({ ...personalInfoData, [name]: updatedValue });
    };


    const handleIdentificationInfoChange = (e: any) => {
        const {name, value} = e.target;
        setIdentificationInfoData({...identificationInfoData, [name]: value});
    };

    const handleAddressInfoChange = (e: any) => {
        const {name, value} = e.target;
        setAddressInfoData({...addressInfoData, [name]: value});
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        await handleRTKQuery(
            async () => {
                return await createMemberApi({
                    requestBody: {
                        ...personalInfoData,
                        ...identificationInfoData,
                        ...addressInfoData,
                        country: country?.value,
                        partyId: partyId,
                        idIssuer: idIssuer,
                        fincenContactId: isPrimaryContact ? contactReferenceId : contactId,
                        stateId: stateId?.value,
                        idType: idType?.value
                    }
                }).unwrap(); // Ensure `unwrap()` is used to handle errors properly
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    if (isPrimaryContact) {
                        setActiveTab(`verify-contact`);
                    } else {
                        setActiveTab(`sign`);
                    }
                }
            }
        );
    };

    const partyFieldLabels = {
        legalFirstName: "Legal First Name",
        legalMiddleInitial: "Legal Middle Initial",
        legalLastName: "Legal Last Name",
        dateOfBirth: "Date of Birth",
        taxId: "Tax ID",
        streetAddress: "Street Address",
        city: "City",
        zip: "ZIP",
        occupation: "Occupation",
        idNumber: "ID Number",
    };

    return (
        <div className="w-full">
            <form onSubmit={handleSubmit}>
                <div className="w-3/5 m-auto mt-4">

                    {
                        currentTab === "1" && <>
                            <p className={"text-2xl text-center text-text-1 mb-4"}>Personal Information</p>

                            {(Object.keys(personalInfoData) as Array<keyof typeof personalInfoData>)
                                .map((key, index) => (
                                    <InputField
                                        key={index}
                                        label={partyFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                        value={personalInfoData[key]}
                                        type={key === "dateOfBirth" ? "date" : "text"}
                                        onChange={handlePersonalInfoChange}
                                        name={key}
                                        labelPosition="left"
                                        required={true}
                                    />
                                ))
                            }

                            {handleButton(() => setActiveTab("verify-code"), () => setCurrentTab("2"), "Continue", personalInfoData)}
                        </>
                    }

                    {
                        currentTab === "2" && <>
                            <p className={"text-2xl text-center text-text-1 mb-4"}>Address Information</p>

                            <SelectField
                                label={"Country"}
                                value={country}
                                placeholder="Select Country..."
                                options={createOptionListForSelectTag(allCountriesApiResult?.data, "name", "id")}
                                onChange={(selectedOption) => {
                                    setCountry(selectedOption)
                                }}
                                required={true}
                            />

                            <SelectField
                                label={"State"}
                                value={stateId}
                                placeholder="Select State..."
                                options={createOptionListForSelectTag(allStatesApiResult?.data, "name", "id")}
                                onChange={(selectedOption) => {
                                    setStateId(selectedOption)
                                }}
                                required={true}
                            />

                            {(Object.keys(addressInfoData) as Array<keyof typeof addressInfoData>)
                                .map((key, index) => (
                                    <InputField
                                        key={index}
                                        label={partyFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                        value={addressInfoData[key]}
                                        type={"text"}
                                        onChange={handleAddressInfoChange}
                                        name={key}
                                        labelPosition="left"
                                        required={true}
                                    />
                                ))
                            }

                            {handleButton(() => setCurrentTab("1"), () => setCurrentTab("3"), "Continue", addressInfoData)}
                        </>
                    }

                    {
                        currentTab === "3" &&
                        <>
                            <p className={"text-2xl text-center text-text-1 mb-4"}>Identification Information</p>

                            <SelectField
                                placeholder='Select ID Type...'
                                value={idType}
                                options={createOptionListForSelectTag(enums?.["MemberIdType"], "displayName", "constant")}
                                onChange={(selectedOption) => {
                                    setIdType(selectedOption);
                                }}
                                label={"ID Type"}
                                isMulti={false}
                                required={true}
                            />

                            {(Object.keys(identificationInfoData) as Array<keyof typeof identificationInfoData>)
                                .map((key, index) => (
                                    <InputField
                                        key={index}
                                        label={partyFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                        value={identificationInfoData[key]}
                                        type={"text"}
                                        onChange={handleIdentificationInfoChange}
                                        name={key}
                                        labelPosition="left"
                                        required={true}
                                    />
                                ))
                            }

                            {(idType?.value === "ALIEN_REGISTRATION" || idType?.value === "OTHER") &&
                                <InputField label="ID Issuer" labelPosition="left" name={"idIssuer"}
                                            onChange={(e: any) => {
                                                setIdIssuer(e.target.value);
                                            }} value={idIssuer || ""} required={true}/>}

                            {handleButton(() => setCurrentTab("2"), handleSubmit, "Submit", identificationInfoData)}
                        </>
                    }

                </div>
            </form>
        </div>
    );
};

export default MemberForm;
