import React, {useEffect, useState} from "react";
import Header from "../Header";
import CodeRequestSection from "../CodeRequestSection";
import {IoIosArrowDown} from "react-icons/io";
import SuccessPage from "../SuccessPage";
import Footer from "../../../components/layout/footer/Footer";
// @ts-ignore
import activeIcon from "../../../images/current.svg"

import QuestionnaireForm from "../form/QuestionnaireForm";
import ContactDetailPage from "../ContactDetailPage";
import ContactForm from "../form/ContactForm";
import MemberForm from "../form/MemberForm";
import SellerForm from "../form/SellerForm";
import BuyerForm from "../form/BuyerForm";
import {useParams} from "react-router-dom";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {useGetContactByIdQuery} from "../contactApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useLazyFindPartyByIdQuery} from "../partyApi";
import SkeletonLoader from "../../../components/common/SkeletonLoader";
import SignatureForm from "../form/SignatureForm";
import VerifyCodeForm from "../form/VerifyCodeForm";
import {resetAllCookies} from "../../../helpers/utils/Utils";

const StepperForm = () => {

    const {contactId} = useParams();

    const contactByIdApi = useGetContactByIdQuery(contactId ?? skipToken);
    const [partyByIdApi, partyByIdResponse] = useLazyFindPartyByIdQuery();

    const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("");
    const [skippedTabs, setSkippedTabs] = useState<any>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [targetTab, setTargetTab] = useState<string>("");
    const [tabsWithUnsavedChanges, setTabsWithUnsavedChanges] = useState<any>([]);
    const [completedSteps, setCompletedSteps] = useState<string[]>([]);
    const [isPrimaryContact, setIsPrimaryContact] = useState<boolean>(false);
    const [partyType, setPartyType] = useState<string>("");
    const [updatingContactId, setUpdatingContactId] = useState<string>("");
    const [contactReferenceId, setContactReferenceId] = useState<string>("");
    const [challengeId, setChallengeId] = useState<string>("");
    const [questionResponse, setQuestionResponse] = useState<any>([]);
    const [orgDetailData, setOrgDetailData] = useState<any>([]);

    useEffect(() => {
        const hash = window.location.hash.replace("#", "");

        if (hash && hash !== "request-verify-code") {
            setIsSidebarVisible(true);
        } else {
            resetAllCookies();
            setIsSidebarVisible(false);
        }
        if (hash) {
            setActiveTab(hash);
        }
    }, []);

    // Update the URL hash when the selectedTab changes
    useEffect(() => {
        if (activeTab) {
            window.location.hash = activeTab;
        }
    }, [activeTab]);

    useEffect(() => {
        let obj = contactByIdApi?.data;
        if (obj) {
            setIsPrimaryContact(obj.primaryContact);
            partyByIdApi(obj.partyId);
        }
    }, [contactByIdApi?.data]);

    useEffect(() => {
        let obj = partyByIdResponse?.data;

        if (obj) {
            setPartyType(obj.type);
        }

    }, [partyByIdResponse?.data]);

    const markStepCompleted = (step: string) => {
        setCompletedSteps((prev) => [...prev, step]);
    };

    const allowedSteps = isPrimaryContact
        ? [
            {label: 'Request & Verify Code', key: 'verify-code', isClickable: false},
            {label: 'Answer pre-qualification questions', key: 'questions', isClickable: false},
            {
                label: 'Enter organization details',
                key: 'organization-detail',
                isClickable: completedSteps.includes('verify-code') && completedSteps.includes('questions')
            },
            {
                label: 'Add & Verify contacts',
                key: 'verify-contact',
                isClickable: completedSteps.includes('verify-code') && completedSteps.includes('questions')
            },
            {label: 'Sign & Complete', key: 'sign', isClickable: false},
        ]
        : [
            {label: 'Request & Verify Code', key: 'verify-code'},
            {label: 'Member Information', key: 'member-detail'},
            {label: 'Sign & Complete', key: 'sign'},
        ];

    const stepsConfig: Record<string, React.ElementType | ((props: any) => JSX.Element)> = {
        "verify-code": (props: any) => <VerifyCodeForm {...props} onComplete={() => markStepCompleted('verify-code')}/>,
        "questions": (props: any) => <QuestionnaireForm {...props} onComplete={() => markStepCompleted('questions')}
                                                        setQuestionResponse={setQuestionResponse}/>,
        "organization-detail": (props: any) => partyType === "SELLER" ?
            <SellerForm {...props} setOrgDetailData={setOrgDetailData}/> :
            <BuyerForm {...props} setOrgDetailData={setOrgDetailData}/>,
        "verify-contact": (props: any) => <ContactDetailPage {...props} setUpdatingContactId={setUpdatingContactId}/>,
        "add-contact": (props: any) => <ContactForm {...props} formType="Add"
                                                    setContactReferenceId={setContactReferenceId}/>,
        "update-contact": (props: any) => <ContactForm {...props} formType="Update"
                                                       updatingContactId={updatingContactId}/>,
        "member-detail": (props: any) => <MemberForm {...props} contactReferenceId={contactReferenceId}/>,
        "sign": (props: any) => <SignatureForm {...props} questionResponse={questionResponse}
                                               orgDetailData={orgDetailData}/>,
        "success": () => <SuccessPage/>,
    };

    return (
        <>
            <div className="pt-12 min-h-screen flex flex-col">
                <Header/>

                {!isSidebarVisible ?
                    (contactByIdApi.isLoading ?
                            <div className="w-full max-w-md m-auto justify-center px-4 py-2 mt-3">
                                {Array.from({length: 6}).map((_, index) => (
                                    <div key={index} className="bg-surface-2 border border-surface-2 rounded-xl mb-3">
                                        <div className="cursor-pointer justify-between p-3">
                                            <div className="gap-1 items-center">
                                                <SkeletonLoader count={1}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className="w-full max-w-md m-auto justify-center px-4 py-2 mt-3 grow">
                                <ul className="pl-4 before:absolute before:left-0 before:top-6 before:bottom-0 before:w-[2px] before:h-[calc(100%-3rem)] before:bg-highlight-1 relative">
                                    <li className="relative mt-3">
                                        <img className="absolute left-[-24px] top-3 w-5 h-5" src={activeIcon}
                                             alt={"current"}/>
                                        <CodeRequestSection setIsSidebarVisible={setIsSidebarVisible}
                                                            phoneNumber={contactByIdApi?.data?.phoneNumber}
                                                            setChallengeId={setChallengeId}
                                                            setActiveTab={setActiveTab}/>
                                    </li>
                                    {allowedSteps.map((item, index) => (
                                        index > 0 &&
                                        <li key={item.key}
                                            className="relative mt-3">
                                            <i className="absolute left-[-21px] top-3 fa-solid fa-circle text-highlight-0 text-xs"/>
                                            <div className="bg-surface-2 border border-surface-2 rounded-xl">
                                                <div className="cursor-pointer flex justify-between p-3">
                                                    <div className="flex gap-1 items-center">
                                                        <h3 className="text-text-2">{item.label}</h3>
                                                    </div>
                                                    <div className="flex gap-3">
                                                        <IoIosArrowDown className="text-lg cursor-pointer"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                    )
                    :
                    <div className="grow">
                        <aside
                            className="pt-12 left-0 sm:translate-x-0 min-h-screen w-60 text-text-1 top-0 transition-transform z-40 bg-surface-2 fixed h-screen">
                            <div className="sm:mt-5 md:mt-0 lg:mt-0 pl-4">
                                <ul className="pl-4 before:absolute before:left-0 before:top-4 before:bottom-0 before:w-[2px] before:h-[calc(100%-2rem)] before:bg-highlight-1 relative">
                                    {allowedSteps.map((tab: any, index: number) => {
                                        const tabIndex = ["verify-code", "questions", "organization-detail", "verify-contact", "add-contact", "member-detail", "sign", "success"].indexOf(tab.key);
                                        const currentIndex = ["verify-code", "questions", "organization-detail", "verify-contact", "add-contact", "member-detail", "sign", "success"].indexOf(activeTab);

                                        return (
                                            <li key={index}
                                                className={`p-2 relative mt-3 ${tab.isClickable && tab.key !== activeTab && !skippedTabs.includes(tab.key) ? 'cursor-pointer' : ''}`}
                                                onClick={() => {
                                                    if (tab.isClickable && tab.key !== activeTab && !skippedTabs.includes(tab.key)) {
                                                        setTargetTab(tab.key);
                                                        setShowConfirmationModal(true);
                                                    }
                                                }}>
                                                {activeTab === tab.key &&
                                                    <img className="absolute left-[-24px] top-3 w-5 h-5"
                                                         src={activeIcon}
                                                         alt={"dd"}/>}

                                                {activeTab !== tab.key && tabIndex > currentIndex &&
                                                    <i className="absolute left-[-21px] top-3 fa-solid fa-circle text-highlight-0 text-xs"/>
                                                }

                                                {tabIndex < currentIndex ? (
                                                    <>
                                                        <i className="absolute left-[-21px] top-3 fa-solid fa-circle text-text-1 text-xs"/>
                                                        <i className={`absolute left-[-23px] top-3 ${skippedTabs.includes(tab.key) ? `fa-solid fa-circle-minus text-surface-6` :
                                                            (tabsWithUnsavedChanges.includes(tab.key) ? `fa-sharp fa-solid fa-circle-exclamation text-warn-3` : `fa-solid fa-circle-check text-highlight-0`)}`}/>
                                                    </>
                                                ) : null}
                                                {tab.label}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </aside>

                        <div className="flex flex-col flex-1 grow">
                            <div className="relative bg-surface-1 w-100 lg:pl-52 md:pl-52 sm:pl-0">
                                <div className="justify-center mt-3 grow flex items-start">
                                    {/*@ts-ignore*/}
                                    {stepsConfig[activeTab] ? stepsConfig[activeTab]({
                                        setActiveTab,
                                        setTabsWithUnsavedChanges,
                                        skippedTabs,
                                        tabsWithUnsavedChanges,
                                        activeTab,
                                        setSkippedTabs,
                                        partyId: contactByIdApi?.data?.partyId,
                                        phoneNumber: contactByIdApi?.data?.phoneNumber,
                                        isPrimaryContact,
                                        challengeId,
                                        partyType,
                                        productId: partyByIdResponse?.data?.productId
                                    }) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Footer/>
            </div>

            {
                showConfirmationModal && <ConfirmationModal title={"Unsaved Changes Warning"}
                                                            bodyMessage={"You have unsaved changes. If you leave now, any unsaved information will be lost. Are you sure you want to continue?"}
                                                            onSubmit={() => {
                                                                tabsWithUnsavedChanges.push(activeTab);
                                                                setActiveTab(targetTab);
                                                            }}
                                                            isLoading={false}
                                                            show={showConfirmationModal}
                                                            setShow={setShowConfirmationModal}/>
            }
        </>
    );
};

export default StepperForm;
