import React, {FC, useState, FormEvent} from "react";
import {skipToken} from "@reduxjs/toolkit/query";
import {IoIosArrowDown} from "react-icons/io";
import {useFindAllDisplayPartyByProductIdQuery} from "../../fincenReporting/partyApi";
import {DetailRow} from "../../../helpers/utils/OrderDetailUtils";
import {FaCirclePlus, FaTrash} from "react-icons/fa6";
import ModalButton from "../../../components/common/form/ModalButton";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useDeleteContactByIdMutation, useSendFincenLinkMutation} from "../../fincenReporting/contactApi";
import {FaCheckCircle, FaEdit, FaLink, FaLocationArrow, FaTimesCircle} from "react-icons/fa";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {getContactStatusStyle, iconButtonStyle} from "../../../helpers/utils/StyleUtils";
import AddEditContactForm from "../modal/AddEditContactForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {useLazyViewFileQuery} from "../orderFilesApi";

interface props {
    productId: string
}

const FincenPartyDetail: FC<props> = ({productId}) => {

    const partyDetailApi = useFindAllDisplayPartyByProductIdQuery(productId ?? skipToken);
    const [selectedId, setSelectedId] = useState<number>();
    const [expandedContacts, setExpandedContacts] = useState<number[]>([]); // Store expanded contact IDs
    const [expandedParties, setExpandedParties] = useState<number[]>([]); // Store expanded contact IDs
    const [deleteContactApi, {isLoading: deleteContactApiResponse}] = useDeleteContactByIdMutation();
    const [sendFincenLinkApi, {isLoading: sendFincenLinkApiResponse}] = useSendFincenLinkMutation();
    const {data: enums} = useGetEnumsQuery("");
    const [viewFile] = useLazyViewFileQuery();

    function getElement(label: string, value: string, addBorder: boolean) {
        return <div className={`border-surface-3 col-span-6 flex mt-1 ${addBorder ? "border-b" : ""}`}>
            <div className="font-semibold mb-2 ml-2 text-text-3 w-1/2">{label}</div>
            <div className="text-text-1 w-1/2">{value}</div>
        </div>
    }

    const toggleCollapse = (id: number) => {
        setExpandedContacts((prev) =>
            prev.includes(id) ? prev.filter((contactId) => contactId !== id) : [...prev, id]
        );
    };

    const togglePartyCollapse = (id: number) => {
        setExpandedParties((prev) =>
            prev.includes(id) ? prev.filter((partyId) => partyId !== id) : [...prev, id]
        );
    };

    const handleDeleteSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!selectedId) {
            return;
        }

        await handleRTKQuery(
            async () => {
                const apiResponse = await deleteContactApi(selectedId).unwrap();
                setExpandedContacts([]);
                return apiResponse;
            }
        );
    };

    const handleSendLinkSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!selectedId) {
            return;
        }

        await handleRTKQuery(
            async () => {
                const apiResponse = await sendFincenLinkApi(selectedId).unwrap();
                setExpandedContacts([]);
                return apiResponse;
            }
        );
    };

    const computePartyStatus = (obj: any) => {
        return obj.detailProvided && obj?.contacts.every((el: any) => el.status === "COMPLETED") ?
            "Completed" :
            "Pending";
    }

    const handleViewDownloadFile = async (fileToDownloadId: string, isDownload: boolean) => {
        viewFile({fileId: fileToDownloadId, isDownload: isDownload})
    }

    return (
        <>
            {partyDetailApi?.data?.map((obj: any, index: number) => (
                <div className="bg-surface-2 rounded-md p-3 mt-3">

                    {/*Party Header*/}
                    <div className={`justify-between flex ${expandedParties.includes(index) ? "border-b border-surface-3 pb-2" : ""}`}>
                        <div
                            className={`flex gap-2 items-center`}>

                            <div
                                className="text-text-1 font-semibold">{getEnumDisplayNameByConstant(enums?.["PartyType"], obj.type)} - {obj.name}</div>
                            {obj.primaryContactAttestation &&
                                <div>
                                    <FaLink className={`text-highlight-3 cursor-pointer`} onClick={() => {
                                        handleViewDownloadFile(obj.primaryContactAttestation.fileId, false).then()
                                    }}/>
                                </div>
                            }
                        </div>

                        <div className="flex gap-3 items-center">
                            <span className="bg-surface-3 text-text-1 text-xs p-1 rounded-md "
                                  title="">{computePartyStatus(obj)}</span>

                            {hasPermission(["FINCON-C"]) && <ModalButton
                                triggerButton={<FaCirclePlus className={iconButtonStyle} title={"Add Contact"}/>}
                                onOpen={() => {
                                }}
                                modal={
                                    <AddEditContactForm
                                        show={true}
                                        setShow={() => {
                                        }}
                                        partyId={obj.id}
                                    />
                                }/>
                            }

                            <IoIosArrowDown
                                className={`text-text-1 text-lg cursor-pointer transition-transform duration-200 ${expandedParties.includes(index) ? "rotate-180" : ""}`}
                                onClick={() => togglePartyCollapse(index)}
                            />
                        </div>
                    </div>

                    {expandedParties.includes(index) && (
                        <div className={`border border-surface-2`}>

                            <DetailRow label={'Type'}
                                       isLoading={false}
                                       value={getEnumDisplayNameByConstant(enums?.["PartyType"], obj.type)}
                            />

                            <div className="col-span-12 grid grid-cols-12 mt-3.5">
                                <div className="col-span-5">
                                    <div className="text-text-3 font-semibold">Detail Provided</div>
                                </div>
                                <div className="col-span-7">
                                    {obj.detailProvided ? <FaCheckCircle className={"text-highlight-7"}/> :
                                        <FaTimesCircle className={"text-error-2"}/>}
                                </div>
                            </div>

                            {obj?.contacts?.map((contact: any, key: number) => (
                                <>
                                    <div
                                        className={`bg-surface-3 border-surface-2 cursor-pointer flex justify-between mt-2 p-1 ${expandedContacts.includes(key) ? "border-b rounded-t-md" : "rounded-md"}`}>
                                        <div className="flex gap-1 items-center">
                                            <h3 className="text-text-1">{contact.name}</h3>
                                            {contact.attestation &&
                                                <FaLink className={`text-highlight-3`} onClick={() => {
                                                    handleViewDownloadFile(contact.attestation.fileId, false).then()
                                                }}/>}
                                        </div>
                                        <div className="flex gap-3 items-center">
                                            <span
                                                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-text-1 ` + getContactStatusStyle(contact.status)}>{getEnumDisplayNameByConstant(enums?.["ContactStatus"], contact.status)}</span>

                                            {contact.status !== "COMPLETED" &&
                                                <ModalButton triggerButton={<FaLocationArrow className={iconButtonStyle}
                                                                                             title={"Send Invite"}/>}
                                                             onOpen={() => setSelectedId(contact.id)}
                                                             modal={
                                                                 <ConfirmationModal
                                                                     show={true}
                                                                     setShow={() => {
                                                                     }}
                                                                     title="Send Invite"
                                                                     bodyMessage="Are you sure you want to send invite to this contact?"
                                                                     onSubmit={handleSendLinkSubmit}
                                                                     isLoading={sendFincenLinkApiResponse}
                                                                 />
                                                             }
                                                />}

                                            {hasPermission(["FINCON-U"]) && <ModalButton
                                                triggerButton={<FaEdit title={"Update Contact"}
                                                                       className={iconButtonStyle}/>}
                                                onOpen={() => setSelectedId(contact.id)}
                                                modal={
                                                    <AddEditContactForm
                                                        show={true}
                                                        setShow={() => {
                                                        }}
                                                        contactId={contact.id}
                                                        partyId={obj.id}
                                                    />
                                                }
                                            />}
                                            {hasPermission(["FINCON-D"]) && <ModalButton
                                                triggerButton={<FaTrash title={"Delete Contact"}
                                                                        className={iconButtonStyle}/>}
                                                onOpen={() => setSelectedId(contact.id)}
                                                modal={
                                                    <ConfirmationModal
                                                        show={true}
                                                        setShow={() => {
                                                        }}
                                                        title="Delete Contact"
                                                        bodyMessage="Are you sure you want to delete this contact?"
                                                        onSubmit={handleDeleteSubmit}
                                                        isLoading={deleteContactApiResponse}
                                                    />
                                                }
                                            />}

                                            <IoIosArrowDown
                                                className={`text-text-1 text-lg cursor-pointer transition-transform duration-200 ${expandedContacts.includes(key) ? "rotate-180" : ""}`}
                                                onClick={() => toggleCollapse(key)}
                                            />

                                        </div>
                                    </div>

                                    {expandedContacts.includes(key) && (
                                        <div className="border border-surface-3 text-text-1">
                                            {getElement("Email", contact.emailAddress, true)}
                                            {getElement("Phone", contact.phoneNumber, true)}
                                            {getElement("Type", getEnumDisplayNameByConstant(enums?.["MemberContactType"], contact.type), false)}
                                        </div>
                                    )}

                                </>
                            ))}

                        </div>
                    )}

                </div>
            ))}
        </>
    )
}
export default FincenPartyDetail;