import React, {FC, FormEvent, useEffect, useState} from "react";
import InputField from "../../../components/common/form/InputField";
import Form from "../../../components/common/form/Form";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {
    useAuthenticateFincenRequestMutation, useRequestFincenMfaChallengeMutation,
    useVerifyFincenOtpMutation
} from "../fincenAuthenticateApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useParams} from "react-router-dom";

interface props {
    activeTab: any,
    setActiveTab: any,
    onComplete: any,
    isPrimaryContact: boolean,
    phoneNumber: string,
    challengeId: string
}

const VerifyCodeForm: FC<props> = ({setActiveTab, onComplete, isPrimaryContact, phoneNumber, challengeId}) => {

    const {contactId} = useParams();
    const [verifyOtpApi, verifyOtpApiResponse] = useVerifyFincenOtpMutation();
    const [requestChallenge, sendRequestChallengeApiResponse] = useRequestFincenMfaChallengeMutation();
    const [authFincenRequest, authFincenRequestApiResponse] = useAuthenticateFincenRequestMutation();

    const [code, setCode] = useState<string>("");
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(60);
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (authFincenRequestApiResponse?.isSuccess) {
            setActiveTab(isPrimaryContact ? "questions" : "member-detail");
            onComplete();
        }
    }, [authFincenRequestApiResponse]);

    useEffect(() => {
        if (timer > 0 && isResendDisabled) {
            const countdown = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else {
            setIsResendDisabled(false);
        }
    }, [timer, isResendDisabled]);


    const startTimer = () => {
        setIsResendDisabled(true);
        setTimer(60);

        const timerInterval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timerInterval); // Clear the interval when the timer ends
                    setIsResendDisabled(false); // Enable the resend button
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const handleResendCode = async () => {
        if (!isResendDisabled) {
            await handleRTKQuery(
                async () => {
                    return requestChallenge(contactId).unwrap();
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        startTimer();
                    }
                }
            )
        }
    };

    useEffect(() => {
        if (isSubmit) {
            setIsResendDisabled(true);
            setTimer(60);
        }
    }, [isSubmit]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (code) {
            let requestBody = {
                challengeId: challengeId,
                otp: code
            }

            await handleRTKQuery(
                async () => {
                    return verifyOtpApi(requestBody).unwrap();
                },
                (isSuccess: any) => {
                    setIsSubmit(true);
                    if (isSuccess) {
                        authFincenRequest({
                            contactId: contactId,
                            mfaToken: isSuccess?.token
                        });
                    }
                }
            )
        }
    }

    return (
        <>
            <div className="w-full p-8 rounded-md max-w-md">
                <p className={"text-2xl text-center text-text-1"}>Verify Code</p>
                <div className="text-text-1 mt-8">
                    <h2 className="text-xl font-semibold text-center">Verification code sent to
                        ***-***{phoneNumber?.slice(-4)}</h2>
                    <h2 className="mt-2 text-center text-xl">Please enter the code below.</h2>

                    <Form onSubmit={handleSubmit} fields={
                        <>
                            <InputField
                                name="code"
                                label=""
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                type="text"
                                required
                                placeholder="Enter Code..."
                                hideLabel={true}
                            />

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Verify Code",
                                            type: "submit",
                                            isLoading: verifyOtpApiResponse.isLoading
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: isResendDisabled ? `Re-send (${timer}s)` : "Re-send",
                                            type: "button",
                                            onClick: (e: any) => {
                                                handleResendCode().then(r => {
                                                });
                                            },
                                            isLoading: (sendRequestChallengeApiResponse.isLoading || authFincenRequestApiResponse.isLoading)
                                        },
                                        buttonType: "button",
                                    }
                                ]}
                                align="center"
                                gap={2}
                                addTopLine={false}
                            />

                        </>}/>
                </div>
            </div>


        </>
    )
}
export default VerifyCodeForm;